<template>
  <div>
    <div class="navigation" v-if="!isScrolled">
      <div class="navigation-header">
        <NavigationBarItem :transparent="transparent" />
      </div>
    </div>
    <transition name="slide-in">
      <div class="navigation-transparent" v-show="isScrolled">
        <div class="navigation-header-transparent">
          <NavigationBarItem transparent />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import NavigationBarItem from './NavigationBarItem.vue';
export default {
  props: {
    transparent: {
      type: Boolean,
      default: false
    }
  },
  components: {
    NavigationBarItem
  },
  data() {
    return {
      isScrolled: false
    }
  },
  methods: {
    scrollHandle() {
      // console.log(document)
      // console.log(document.documentElement)
      const ST = document.documentElement.scrollTop;
      // debugger
      this.isScrolled = ST > 94;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandle, false);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandle, false);
  }
}
</script>
<style lang="sass" scope>
.navigation {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
  .navigation-header {
    background-color: transparent;

    .navigation-header-box {
      height: 96px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;
      margin: 0 auto;
      .logo {
        width: 183px;
      }
    }
  }
}
.navigation-transparent {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  .navigation-header-transparent {
    background-color: #fff;
    box-shadow: 2px 2px 6px 0 hsla(0, 0%, 80%, 0.5);
  }
}
.slide-in-enter-active {
  transition: all 0.25s ease;
}
.slide-in-leave-active {
  transition: all 0.2s ease;
}
.slide-in-enter,
.slide-in-leave-to {
  transform: translate3d(0, -100%, 0);
}
</style>
