export const svgList = [
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/renwenhei/renwenjidianB.svg",
    active:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/renwenbai/renwenjidianW.svg",
    activeIndex: 0
  },

  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/renwenhei/renwenguanhuaiB.svg",
    active:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/renwenbai/renwenqinghuaiW.svg",
    activeIndex: 1
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/renwenhei/shenmeiqingquB.svg",
    active:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/renwenbai/shenmeiqingquW.svg",
    activeIndex: 2
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/lixingsiwei.svg"
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/renwenhei/pipanzhiyiB.svg",
    active:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/renwenbai/pipanzhiyiW.svg",
    activeIndex: 4
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/yongyutanjiu.svg"
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/angle/lexueshanxue.svg"
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/angle/qinyufansi.svg"
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/angle/xinxiyishi.svg"
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/angle/zhenaishengming.svg"
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/angle/jianquanrenge.svg"
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/angle/ziwoguanlibai.svg"
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/shehuizeren.svg"
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/guojiarentong.svg"
  },
  {
    default:
      "https://editor.xesimg.com/tailor/resource/laodongyishi-1650337583010.svg"
  },
  {
    default:
      "https://editor.xesimg.com/tailor/resource/guojilijie-1650338737780.svg"
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/wentijiejue.svg"
  },
  {
    default:
      "https://static0.xesimg.com/platform-fe/mallwww/svg/jishuyunyong.svg"
  }
  // 'https://static0.xesimg.com/platform-fe/mallwww/svg/renwenguanhuai.svg',
  // 'https://static0.xesimg.com/platform-fe/mallwww/svg/lixingsiwei.svg',
  // 'https://static0.xesimg.com/platform-fe/mallwww/svg/wentijiejue.svg',
  // 'https://static0.xesimg.com/platform-fe/mallwww/svg/jishuyunyong.svg',
];
export const dataArr = [
  {
    value: 10,
    name: "",
    emphasis: {},
    activeIndex: 0
  },
  {
    value: 10,
    name: "",
    emphasis: {},
    activeIndex: 1
  },
  {
    value: 10,
    name: "",
    emphasis: {},
    activeIndex: 2
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {},
    activeIndex: 4
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  },
  {
    value: 10,
    name: "",
    emphasis: {
      disabled: true
    }
  }
];
export const baseImg =
  "https://static0.xesimg.com/platform-fe/mallwww/svg/Pei.svg";
