<template>
  <div id="app">
    <NavigationBar />
    <Humanities />
    <PageFooter />
  </div>
</template>

<script>
import Humanities from './views/attainment/humanities'
import NavigationBar from './views/attainment/components/NavigationBar.vue'
import PageFooter from './views/attainment/components/PageFooter.vue'

export default {
  name: 'App',
  components: {
    NavigationBar,
    PageFooter,
    Humanities
  }
}
</script>

<style>
* {
  margin:0;

  padding:0;
}
</style>
