import Vue from 'vue'
import App from './App.vue'
import VueLazyload from 'vue-lazyload'
import '@/views/attainment/utils/animate'
Vue.use(VueLazyload, {
  preLoad: 1,
  attempt: 1
});
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
