<template>
  <div ref="pageIndex" class="root humanities">
    <banner
      v-bind="{...data.banner}"
    />
    <Content :pageData="data.pageData" />
  </div>
</template>

<script>

import containerMock from "./mock/container";
// import mixin from '../mixin'

export default {
  // mixins: [mixin],
  // eslint-disable-next-line vue/multi-word-component-names
  name: "science",
  components: {
    banner: () => import("../components/video-player-banner"),
    Content: () => import("../components/Content"),
    // Navigator: () =>
    //   import("../components/navigator"),
  },
  data() {
    return {
      data: {},
      isDebugger: true,
    };
  },
  created() {
    this.data = containerMock.data
  },
  mounted() {
  },
};
</script>


<style lang="scss" scoped>
.root {
  background: #ffffff;
  min-height: 100vh;
}

.humanities {
  /deep/ .slogan-text {
    height: 98px;
    min-height: auto !important;
    padding: 20px 0 50px;
  }
}
</style>
