<template>
  <div class="navigation-header-box">
    <div class="logo">
      <!-- https://editor.xesimg.com/tailor/resource/login-1662100774989.png -->
      <!-- <img v-if="!transparent" src="https://static0.xesimg.com/platform-fe/mall_www_pc/online/assets/logo-default.fbdf375.png" alt="" /> -->
      <!-- <img v-else src="https://static0.xesimg.com/platform-fe/mall_www_pc/online/assets/logo-default.fbdf375.png" alt="" /> -->
    </div>
    <div class="nav-list" :class="{ transparent: transparent }">
      <div class="nav-list-item" @click="openPage('/')" :class="{ active: true }">首页</div>
      <!-- <div class="nav-list-item" @click="openPage('/d')" :class="{ active: curPath === '/d' }">下载客户端</div> -->
    </div>
  </div>
</template>
<script>

export default {
  props: {
    transparent: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openPage(path) {
      this.$router.push(path)
    }
  }
}

</script>
<style lang="sass" scope>
.navigation-header-box {
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  .logo {
    // width: 183px;
    display: block;
    height: 52px;
    width: 207px;
    flex-shrink: 0;
    img {
      width: 100%;
    }
  }
  .nav-list {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    .nav-list-item {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 19px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      margin-left: 50px;
      padding: 11px 0;
      cursor: pointer;
      white-space: nowrap;
      color: #fff;
      &.active {
        &::after {
          content: ' ';
          position: absolute;
          bottom: -14px;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: 20px;
          height: 2px;
          border-radius: 1px;
          background-color: #fff;
        }
      }
    }
    &.transparent {
      .nav-list-item {
        color: #333;
        &.active {
          color: #e94040;
          &::after {
            background-color: #e94040;
          }
        }
      }
    }
  }
}
</style>
