import { svgList, dataArr, baseImg } from "./base";

const data = {
  stat: 1,
  msg: "",
  data: {
    banner: {
      poster:
        "https://editor.xesimg.com/tailor/resource/humanities-1653048117858.jpg",
      videoUrl:
        "https://editor.xesimg.com/tailor/resource/renwen-1650703499398.mp4",
      completeVideoUrl:
        "https://static0.xesimg.com/platform-fe/mallwww/video/humanities.mp4",
      completeHightVideoUrl:
        "https://static0.xesimg.com/platform-fe/mallwww/video/humanitiesFullHight1.mp4",
      slogan: "https://static0.xesimg.com/platform-fe/mallwww/svg/humanity.svg"
    },
    pageData: {
      type: "humanities",
      whyLearning: {
        type: "humanities",
        title: "为什么要让孩子学人文创作？",
        decs: "孩子平时表现出来的以下问题，其实都和缺乏思辨性思维有关",
        cardList: [
          {
            img:
              "https://editor.xesimg.com/tailor/resource/card2-1650623514793.png",
            imgAlt: "做作业的男孩",
            title: "读了大量书，<br/>创作时依然无从下手？",
            btns: ["不会立意", "很难组织观点"]
          },
          {
            img:
              "https://editor.xesimg.com/tailor/resource/card1-1650623514792.png",
            title: "文章理解和分析，<br/> 语言总是缺乏条理？",
            imgAlt: "看书的女孩",
            btns: ["不能准确理解", "没有逻辑性"]
          },
          {
            img:
              "https://editor.xesimg.com/tailor/resource/card3-1650623514794.png",
            title: "容易人云亦云，<br/> 很难提炼和表达自己的观点？",
            imgAlt: "思考的男孩",
            btns: ["缺乏独立思考", "缺乏判断力"]
          }
        ]
      },
      core: {
        title: "人文创作培养哪些核心素养？",
        decs:
          "以“中国学生发展核心素养”为依托，人文创作课程重在培养孩子“批判质疑”、“人文积淀”、“人文情怀”和“审美情趣”4大核心素养。",
        content: {
          echart: {
            defaultIndex: 4,
            baseImg,
            svgList,
            dataArr
          },
          coreList: [
            {
              title: "批判质疑",
              content:
                "在多维课堂互动中培养孩子勇于探究、深度思考和多角度思考，帮助孩子养成透过现象看本质的能力和独立思考判断的能力。",
              active: 4
            },
            {
              title: "人文积淀",
              content:
                "在阅读经典中吸收古今中外历史文化的知识和成果，帮助孩子理解和掌握人文思想中所蕴含的认识与实践的方法。",
              active: 0
            },
            {
              title: "人文情怀",
              content:
                "在历史和文化的旁征博引中积淀人文情怀，培养孩子以人为本的意识，懂得尊重、维护尊严和价值，拥有关心生存、发展和幸福的能力。",
              active: 1
            },
            {
              title: "审美情趣",
              content:
                "在美育中陶冶情操，引导孩子发现美、体验美、感知美、创造美，帮助孩子养成高尚纯洁的人格，追求美好的事物。",
              active: 2
            }
          ]
        }
      },
      educateSystem: {
        title: "人文创作的培养体系",
        imgAlt: "课表",
        imgSrc: "https://static0.xesimg.com/platform-fe/mallwww/svg/sysmle.svg"
      },
      advantage: {
        title: "人文创作有什么优势？",
        tabs: [
          {
            title: "以孩子为中心的课堂体验",
            cardList: [
              {
                imgSrc:
                  "https://editor.xesimg.com/tailor/resource/1-1651141532821.png",
                title: "游戏化互动<br/>激发学习兴趣",
                imgAlt: "游戏化互动",
                content:
                  "采用歌曲演绎导入、身体操情绪激活、竞赛互动破冰、超认知内容设定等方式激发兴趣。"
              },
              {
                imgSrc:
                  "https://editor.xesimg.com/tailor/resource/2-1651141532823.png",
                title: "项目式教学<br/>激发创造力",
                imgAlt: "项目式教学",
                content:
                  "每节课发布项目任务，让孩子在课堂中自主学习、主动参与、大胆尝试，激发创造能力。"
              },
              {
                imgSrc:
                  "https://editor.xesimg.com/tailor/resource/3-1651141532823.png",
                title: "探究式教学<br/>引导自主思考",
                imgAlt: "探究式教学",
                content:
                  "以辩论、小组思考、角色扮演等方式，引导学生合作探索问题，寻找答案，培养正确的价值观。"
              },
              {
                imgSrc:
                  "https://editor.xesimg.com/tailor/resource/4-1651141532824.png",
                title: "启发式教学<br/>批判性话题辩论",
                imgAlt: "启发式教学",
                content:
                  "通过话题式讨论，引导学生自主思考，让孩子结合故事组织论据，通过小组讨论提炼观点，进行主题辩证。"
              },
              {
                imgSrc:
                  "https://editor.xesimg.com/tailor/resource/5-1651141532824.png",
                title: "主题式呈现<br/>乐分享善表达",
                imgAlt: "主题式呈现",
                content:
                  "师生、同学之间分享课堂收获，交流学习感悟，分小组展示，进行主题汇报。"
              }
            ]
          },
          {
            title: "丰富有趣的教学模式",
            cardList: [
              {
                imgSrc:
                  "https://editor.xesimg.com/tailor/resource/com1-1653648485870.png",
                imgAlt: "专属学习系统，在线功能丰富",
                desc: "专属学习系统，在线功能丰富"
              },
              {
                imgSrc:
                  "https://editor.xesimg.com/tailor/resource/combine2-1650944833338.png",
                imgAlt: "全景动画引入",
                desc: "全景动画引入，内容生动有趣"
              },
              {
                imgSrc:
                  "https://editor.xesimg.com/tailor/resource/combine3-1650944833338.png",
                imgAlt: "H5互动课件",
                desc: "H5互动课件，效果实时反馈"
              }
            ]
          },
          {
            title: "博学尚美的师资团队",
            card: {
              title:
                "人文创作的师资团队，每一位老师都是博古通今的历史文化传播者，风趣幽默的课堂情绪调动者和懂得欣赏的审美情趣引导者。",
              imgAlt: "师资团队",
              imgSrc:
                "https://editor.xesimg.com/tailor/resource/team3-1651207688705.png"
            }
          }
        ]
      },
      // classroom: {
      //   title: "人文创作的课堂",
      //   subTitle: "精彩课堂",
      //   video: {
      //     poster:
      //       "https://editor.xesimg.com/tailor/resource/humanitiesPoster1-1650957190634.jpg",
      //     src:
      //       "https://static0.xesimg.com/platform-fe/mallwww/video/classroom.mp4"
      //   },
      //   showCardList: true,
      //   thirdTitle: "表达瞬间",
      //   VideoCardList: [
      //     {
      //       poster:
      //         "https://editor.xesimg.com/tailor/resource/humanitiesPoster2-1650957190635.jpg",
      //       src:
      //         "https://editor.xesimg.com/tailor/resource/moment1-1650703248160.mp4"
      //     },
      //     {
      //       poster:
      //         "https://editor.xesimg.com/tailor/resource/humanitiesPoster3-1650957190635.jpg",
      //       src:
      //         "https://editor.xesimg.com/tailor/resource/moment2-1650703248161.mp4"
      //     }
      //   ]
      // },
      // footer: {
      //   img:
      //     "https://editor.xesimg.com/tailor/resource/renwen-1650685406472.png",
      //   imgAlt: "人文创作",
      //   title: "人文创作读书计划",
      //   titleTag: "体验课",
      //   qrCodeUrl:
      //     "https://editor.xesimg.com/tailor/resource/humanities-qr-1666948081467.png",
      //   subTitle:
      //     "3天研读三册文学经典&nbsp;&nbsp;&nbsp;&nbsp;专享72小时线上辅导"
      // }
    }
  }
};
export default data;
